<template>
    <div>
		<b-modal ref="modalCourrierActes" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("horse.print_infos_horse") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="template"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="templates"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group">
					<label for="horses" class="col-form-label">{{ $t("horse.format") }} *</label>
					<e-select
						v-model="format"
						id="format"
						label="name"
						:placeholder="labelTitleFormat"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:selectLabel="enter_to_select"
						:options="formats"
						:searchable="true"
						:show-labels="false"
						:allow-empty="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group">
					<label for="languages" class="col-form-label">{{ $t("global.language") }}</label>
					<e-select
						v-model="localeSelected"
						id="languages"
						label="title"
						track-by="code"
						:options="locales"
						:allow-empty="false"
					/>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.print") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Model from "@/mixins/Model.js"
import Actes from "@/mixins/Actes.js"
import Vue from "vue"

export default {
	name: "modalCourrierActes",
	mixins: [Actes, Model],
	data () {
		return {
			formats: [],
			templates: [],
			format: null,
			template: null,
			processing: false,
			acte_ids: null,
			localeSelected: null,
			locales: this.getLocales(),

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.localeSelected = this.locales.find(lang => lang.code == (Vue.i18n.locale()))
			this.template = null
			this.formats = this.getAllFormats()
			this.templates = await this.loadAllModel(0, ['actes'])
		},
		openModal(acte_ids) {
			this.acte_ids = acte_ids
			this.$refs.modalCourrierActes.show()
		},
		async checkForm() {
			if(this.template && this.format) {
				this.processing = true
				this.printActs(this.template.model_id, this.acte_ids, this.localeSelected.code, this.format.id)
				.then(() => {
					this.processing = false
					this.$refs.modalCourrierActes.hide()
				})
			}
			else {
				this.failureToast()
			}
		}
	}
}
</script>